













import { defineComponent, onBeforeMount } from '@nuxtjs/composition-api';
import RelatedProducts from '~/components/organisms/Product/RelatedProducts/RelatedProducts.vue';
import { useProductRelated } from '~/composables';
import { limitByKeysCustomerFilter } from '~/helpers/commercetools/graphql/limitByKeysCustomerFilter';
import extractBestsellerValidSKUs from '~/helpers/cms/extractBestsellerValidSKUs';

export default defineComponent({
  name: 'HomeProducts',
  components: {
    RelatedProducts
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    commaSeparatedSkus: {
      type: String,
      default: ''
    },
    seeAllLinkText: {
      type: String,
      default: null
    },
    seeAllLinkSlug: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const skuListCacheId = 'bestsellerProducts' + props.commaSeparatedSkus
      .replace(/,/g, '')
      .replace(/ /g, '');
    const {
      productsInStock: bestsellerProductsBasedOnSkuList,
      search: searchProductsBasedOnSkus,
      loading
    } = useProductRelated(skuListCacheId);

    onBeforeMount(async () => {
      const extractionResult = extractBestsellerValidSKUs(props.commaSeparatedSkus);

      if (extractionResult.skus.length > 0) {
        const searchParams = {
          customFilters: limitByKeysCustomerFilter(extractionResult.skus)
        };
        await searchProductsBasedOnSkus(searchParams);
      }

      extractionResult.warnings.forEach(warning => {
        console.warn(warning);
      });
    });

    return {
      bestsellerProductsBasedOnSkuList,
      loading
    };
  }
});

