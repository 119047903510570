


































import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { useRouteBestPriceGuarantee, useProductDeliveryDate, useIntegrations } from '~/composables';
import RelatedProduct from '~/components/organisms/Product/RelatedProducts/RelatedProduct.vue';
import { RELATED_PRODUCTS_CONFIGURATION } from '~/constants/simpleCarousel';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';

export default defineComponent({
  name: 'RelatedProducts',
  components: {
    RelatedProduct,
    Link,
    SimpleCarousel,
    ProductAnchorTitle
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    products: {
      type: Array as PropType<ProductVariant[]>,
      default: () => {
        return [];
      }
    },
    seeAllLinkText: {
      type: String,
      default: null
    },
    seeAllLinkSlug: {
      type: String,
      default: null
    },
    listingAccessories: {
      type: Boolean,
      default: false
    },
    parentProduct: {
      type: Object as PropType<ProductVariant>,
      default: null
    }
  },
  setup(props) {
    const { priceGuaranteeUrl } = useRouteBestPriceGuarantee();
    const { search: searchProductDeliveryDate, parcelDeliveryDate, freightDeliveryDate } = useProductDeliveryDate(true);

    const { $tagManager } = useIntegrations();

    onMounted(() => {
      searchProductDeliveryDate();

      if (props.listingAccessories && props.parentProduct.sku) {
        $tagManager.events.triggerViewAccessoriesTags(props.products, props.parentProduct.sku);
      }
    });

    const getParentProduct = () => {
      if (props.listingAccessories && props.parentProduct) {
        return props.parentProduct;
      }
    };

    return {
      LinkType,
      RELATED_PRODUCTS_CONFIGURATION,
      priceGuaranteeUrl,
      parcelDeliveryDate,
      freightDeliveryDate,
      getParentProduct
    };
  }
});

