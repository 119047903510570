


























import { productGetters } from '@vsf-enterprise/commercetools';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import type { ComputedRef, PropType, Ref } from '@nuxtjs/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import ProductCard from '~/components/molecules/Product/ProductCard.vue';
import { useI18n, useProductPrice, useIntegrations } from '~/composables';
import { getProductQuantity } from '~/helpers/product/getProductStockInfo/getProductStockInfo';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { PriceInformation } from '~/composables/useProductPrice';
import { extractLocalisedAttributes } from '~/helpers/product/extractLocalisedAttributes';
import isString from '~/helpers/dataStructure/isString';

export default defineComponent({
  name: 'RelatedProduct',
  components: {
    ProductCard
  },
  props: {
    product: {
      type: Object as PropType<ProductVariantExtended>,
      required: true
    },
    priceGuaranteeUrl: {
      type: String,
      required: true
    },
    parcelDeliveryDate: {
      type: String,
      default: ''
    },
    freightDeliveryDate: {
      type: String,
      default: ''
    },
    accessoryParentProduct: {
      type: Object as PropType<ProductVariant>,
      default: () => {
        return null;
      }
    }
  },
  setup(props) {
    const productRef: Ref<ProductVariantExtended> = ref(props.product);

    const { languageAndCountry } = useI18n();
    const { getPriceInformation } = useProductPrice();

    const productName: ComputedRef<string> = computed(() => productGetters.getName(productRef.value));
    const productMainImage: ComputedRef<string> = computed(() => productGetters.getCoverImage(productRef.value));
    const sku: ComputedRef<string> = computed(() => productGetters.getSku(productRef.value));
    const reviewsCount: ComputedRef<number> = computed(() => productGetters.getTotalReviews(productRef.value));
    const rating: ComputedRef<number> = computed(() => productGetters.getAverageRating(productRef.value));
    const stockCount: ComputedRef<number> = computed(() => getProductQuantity(productRef.value));
    const localisedAttributes: ComputedRef<ProductProcessedAttributes> = computed(() => extractLocalisedAttributes(
      props.product, [
        PRODUCT_ATTRIBUTES.TAGS,
        PRODUCT_ATTRIBUTES.BRAND,
        PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT,
        PRODUCT_ATTRIBUTES.STANDARD_PRICE,
        PRODUCT_ATTRIBUTES.HIGHLIGHTS,
        PRODUCT_ATTRIBUTES.ENERGY_RATING
      ],
      languageAndCountry.value
    ));

    const dimensions = computed(() => {
      const productDimension = productGetters.getAttributes(
        props.product,
        [PRODUCT_ATTRIBUTES.NETTO_HEIGHT, PRODUCT_ATTRIBUTES.NETTO_WIDTH, PRODUCT_ATTRIBUTES.NETTO_LENGTH]
      );
      const height = productDimension[PRODUCT_ATTRIBUTES.NETTO_HEIGHT];
      const width = productDimension[PRODUCT_ATTRIBUTES.NETTO_WIDTH];
      const length = productDimension[PRODUCT_ATTRIBUTES.NETTO_LENGTH];
      const hasDimensions = !!height && !!width && !!length;
      return {
        height,
        width,
        length,
        hasDimensions
      };
    });

    const priceInfo: ComputedRef<PriceInformation> =
      computed(() => getPriceInformation(productRef, localisedAttributes));

    const { $tagManager } = useIntegrations();

    const relatedProductClicked = () => {
      if (props.accessoryParentProduct?.sku && props.product?.sku) {
        $tagManager.events.triggerClickAccessoryTags(
          props.product.sku,
          props.accessoryParentProduct.sku
        );
      }
    };

    const releaseDate = computed(() =>
      productGetters.getAttributes(props.product, [PRODUCT_ATTRIBUTES.RELEASE_DATE])?.releaseDate
    );
    const releaseDateString = computed(() => isString(releaseDate.value) ? releaseDate.value : '');

    return {
      PRODUCT_ATTRIBUTES,
      productName,
      sku,
      reviewsCount,
      rating,
      stockCount,
      localisedAttributes,
      priceInfo,
      dimensions,
      productMainImage,
      releaseDateString,
      relatedProductClicked
    };
  }
});
